<template>
  <div style="margin: 5px">
    <section class="text-center" v-if="this.withRsvp">
        <h4 class="section-header" style="font-weight: bold">
          <i class="tim-icons icon-chat-33"></i> GET IN TOUCH
        </h4>
      <slot>
      </slot>
      <RsvpViaDialog
        :text="ctaText"
      ></RsvpViaDialog>
      <br>
      <br>
    </section>

    <section class="col-sm-12"
             style="padding: 0px"
             :style="{ background: `linear-gradient(${this.primaryColor}, black )` }">


      <h4 class="section-header">
        <i class="tim-icons icon-settings"></i>&nbsp;TOOLS
      </h4>
      <div style="margin: 10px;padding: 10px" class="text-left">
        <small >Tools to elevate your party time.</small>
      </div>
      <MiniCard
        title="Event Calendar"
        text="Do Not Miss A Thing !"
        link="/los-cabos-mexico-event-calendar"
        image="https://imgix.cosmicjs.com/d944c4a0-9cf4-11ee-93ed-8bfbf1aced8f-image.png"
      ></MiniCard>
      <MiniCard
        title="Top Clubs"
        text="Dance & Have Fun"
        link="/los-cabos-clubs"
        image="https://imgix.cosmicjs.com/bc3f0f60-f62b-11ee-b555-0d0678c27dd7-Taboo_Los_Cabos___tabooloscabos.jpg"
      ></MiniCard>
      <MiniCard
        title="Nighlife Guide"
        text="What, Where?"
        link="/los-cabos-nightlife"
        image="https://imgix.cosmicjs.com/5b93fb90-2aa5-11ef-adb1-8b946b3a80e4-tale-of-us-sundream-baja.png"
      ></MiniCard>
    </section>


    <section
      class="col-sm-12"
      style="padding: 0px"
      :style="{ background: `linear-gradient(${this.primaryColor}, black )` }">
      <h4 class="section-header">
        <i class="tim-icons icon-trophy" @click="clicked=true"></i>&nbsp; POPULAR
      </h4>

      <DynamicLinks :links="links"
                    :style="{ background: `linear-gradient(45deg, black, ${this.primaryColor})` }"
      ></DynamicLinks>

    </section>

    <div class="card text-center centered" style="padding: 30px">
      <small style="font-weight: lighter;font-size: 10px">CABO.PARTY</small>
    </div>
  </div>
</template>
<script>
import DynamicLinks from "@/components/DynamicLinks"
import MiniCard from '@/components/Cards/MiniCard'
import RsvpViaDialog from "@/components/Reservations/RsvpViaDialog"
// import ImgCard from "@/components/Cards/ImgCard";

export default {
  name: 'SmartMenu',
  data: () => {
    return {
      clicked: false,
      sanDJ: 'https://imgix.cosmicjs.com/f9568ec0-4160-11ef-a504-63e081e4680f-santo-dj.jpg',
      tpLogo: 'https://imgix.cosmicjs.com/07af7270-5002-11ef-b1ea-f56c65dfade9-tulum-party-insta.jpg',
      tulumPartyLogo: 'https://imgix.cosmicjs.com/3acdf350-9b87-11ee-b34c-99d1d89e42eb-tp-logo-40pt.png?w=105&h=15&fit=clamp'

    }
  },
  components: {DynamicLinks, MiniCard, RsvpViaDialog},
  props: {
    links: {
      type: Array,
    },
    logo: {
      type: String,
      default: ''
    }, primaryColor: {
      type: String,
      default: 'rgb(48, 171, 198) '
    },
    topSectionName: {
      type: String,
      default: 'GET IN TOUCH'
    },
    ctaText: {
      type: String,
      default: 'Hit us up!'
    },
    withRsvp: {
      type: Boolean,
      default: true
    },
  }
}
</script>
